import React, { useCallback, useRef, useState } from 'react';
// import cn from 'classnames';
import Unmute from '../../../icons/mute.svg';
import Mute from '../../../icons/unmute.svg';

import { MuteButton, Wrapper } from './Video.styles';
import { useBreakpoint } from '../../../utils';
import { Icon } from '../../../components/icons';
import { Link } from 'gatsby';

/*baseline, products, */
const HomepageCoverVideo = ({ }) => {
  const device = useBreakpoint();
  const [loaded, setLoaded] = useState(false);
  const [playing, setPlaying] = useState(false);
  const [muted, setMuted] = useState(true);

  const videoRef = useRef(null);
  const isMobile = device === 'mobile';
  const isSSR = device === 'ssr';

  const onVideoClicked = useCallback((e) => {
    const player = videoRef?.current;
    if (!player) {
      return;
    }

    if (player.paused) {
      player.play();
    }
  }, []);

  const onVideoPlay = useCallback((e) => {
    const player = videoRef?.current;

    if (!player) {
      return;
    }

    setPlaying(!player.paused);
  }, []);

  const onLoaded = useCallback((e) => {
    setLoaded(videoRef.current.readyState >= 2);
  });

  // const showPlayButton = loaded && !playing;
  console.log('muted', muted);
  return (
    <Wrapper>
      <h1>Ipone spécialiste des huiles pour moto et scooter</h1>
      <div className="container">
        <Link to="/fr/eshop/produit/stroke-4-racing-10w40" className="video" onClick={onVideoClicked}>
          {playing && (
            <MuteButton
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setMuted((value) => !value);
                return false;
              }}
              className={muted ? 'unmute' : 'mute'}
            >
              <Icon src={muted ? Unmute : Mute} />
            </MuteButton>
          )}
        </Link>
        <div className="fx">
          {!isSSR && !isMobile && (
            <video
              ref={videoRef}
              autoPlay
              loop
              muted={muted}
              playsInline
              width="100%"
              className="desktop"
              onPlaying={onVideoPlay}
              onLoadedData={onLoaded}
            >
              <source
                src="https://player.vimeo.com/progressive_redirect/playback/1007705353/rendition/1080p/file.mp4?loc=external&signature=989981170aa32fe407208933f7f0079c497d2c8116219e8ce21007b743bcb80b"
              />

            </video>
          )}
          {!isSSR && isMobile && (
            <video
              ref={videoRef}
              autoPlay
              muted={muted}
              loop
              playsInline
              width="100%"
              className="mobile"
              onPlaying={onVideoPlay}
              onLoadedData={onLoaded}
            >
              <source
                src="https://player.vimeo.com/progressive_redirect/playback/1007704575/rendition/720p/file.mp4?loc=external&signature=e6bcc98c3fe8b5ec362ccaadbd44ff1f5dc4b846f2656066ec951c91f6384324"
              />

            </video>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default HomepageCoverVideo;
